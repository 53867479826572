import { useEffect, useState } from "react";

export const useLoadingPercent = () => {
    const [percent, setPercent] = useState(0);
    useEffect(() => {
        let interval: any;
        if (percent < 100) {
            interval = setInterval(() => {
                setPercent((prev) => {
                    if (prev === 100) {
                        clearInterval(interval);
                        return 100;
                    }
                    return prev + 10;
                });
            }, 150);
        }
        return () => {
            clearInterval(interval);
        };
    }, []);
    return percent;
}

// 계좌번호 string 3자리 당 '-' 추가
export const formatAccountNo = (accountNo: string) => {
    const accountNoArray = accountNo.split("");
    const formattedAccountNo = accountNoArray.reduce((acc, cur, idx) => {
        return idx % 3 === 0 && idx !== 0 ? acc + "-" + cur : acc + cur;
    }, "");
    return formattedAccountNo;
}

export const formatDisplayAccountNo = (accountNo: string) => {
    if (!accountNo) return '';
    const accountNoArray = accountNo.slice(0, -2);
    const stockNo = accountNo.slice(-2);
    const formattedAccountNo = formatAccountNo(accountNoArray);

    return `${formattedAccountNo} ${stockNo}`;
}